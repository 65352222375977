/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "../styles.scss"
import styles from "./layout-info.module.scss"
import logoBanner from "./../images/logo-banner.png"
import 'bootstrap/dist/css/bootstrap.css';


class LayoutInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = { menuToggled: false };
    this.render = this.render.bind(this)
  }

  toggleMenu = () => {
    const currentState = this.state.menuToggled;
    this.setState({ menuToggled: !currentState });
  };

   pathIncludes (word) {
    if (typeof window !== 'undefined') { // window is not available at build time
      return window.location.href.includes(word) ? 'active' : '';
    }
    return '';
    
  }

  render() {
    return (
      
      <div>

        <nav className="navbar navbar-expand-md  navbar-expand-lg  navbar-expand-xl  navbar-light leirskole-light justify-content-lg-center justify-content-xl-center">
                
          <div className={`navbar-brand ${styles.header}`}>
              <img src={logoBanner} className="d-none d-sm-block" alt="Leirskoledagboken AS sin logo" />
              <span className="d-sm-none text-uppercase">Leirskoledagboken</span>
          </div>
          <button className="navbar-toggler" type="button" 
             aria-controls="navbarNavAltMarkup" aria-expanded={this.state.menuToggled} aria-label="Toggle menyen av og på" onClick={this.toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse flex-grow-0 ${this.state.menuToggled ? 'show' : ''}`} id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <a className={`nav-item nav-link ${this.pathIncludes('about')}`} href="/about/">Om leirskoledagboken</a>
              <a className={`nav-item nav-link`} href="/">Bestill</a>
              <a className={`nav-item nav-link d-block d-sm-none ${this.pathIncludes('privacy')} `} href="/privacy-policy/">Personvernerklæring</a>
              <a className={`nav-item nav-link d-block d-sm-none ${this.pathIncludes('kjops')} `} href="/kjops-vilkaar/">Kjøpsvilkår</a>
            </div>
            <div className="d-block d-sm-none">
              <hr />
              <div >
                Leirskoledagboken AS<br />
              Nedre Rælingsveg 610, 2009 Nordby<br />
            Tlf: 910 01 395<br />
              </div>

              <div>

              Organisasjonsnummer: 913 868 722
              </div>
              <div >
                <a href="mailto:kundeservice@leirskoledagboken.no">kundeservice@leirskoledagboken.no</a>
              </div>

            </div>

          </div>
        </nav>

        <main className={`${styles.content} card`}>
          <div className="card-body">
            {this.props.children}
          </div>
        </main>

        <footer className={`${styles.footer} fixed-bottom d-none d-sm-block`}>
          <div className="container mx-1 mx-lg-auto">
            <div className="row">
              <div className="col-sm-4">
                Leirskoledagboken AS<br />
                Nedre Rælingsveg 610, 2009 Nordby<br />
            Tlf: 910 01 395<br />
              </div>
              <div className="col-sm-4">
                <a href="/privacy-policy/">Personvernerklæring</a>
                <br />
                <a href="/kjops-vilkaar/">Kjøpsvilkår</a>
              </div>
              <div className="col-sm-4">
              Organisasjonsnummer: 913 868 722<br />
                <a href="mailto:kundeservice@leirskoledagboken.no">kundeservice@leirskoledagboken.no</a>
                <br />
                <span className="d-none d-md-block">© Powered by Microprog AS</span>
              </div>

            </div>
          </div>
        </footer>

      </div >

    )
  }
}

LayoutInfo.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutInfo;
